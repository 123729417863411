import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
// import Slider from '@material-ui/core/Slider';

export default function Filter({
    items,
    setFilteredItems,
    setReset,
    hideBrands,
    hideCategories,
    shouldReset,
    setShouldReset,
    max,
    requireCategoryFilter,
}) {
    const [recalculate, setRecalculate] = useState(false);

    const [options, setOptions] = useState({});

    const [brandFilter, setBrandFilter] = useState([]);
    const [categoryFilter, setCategoryFilter] = useState([]);
    const [materialFilter, setMaterialFilter] = useState([]);
    const [widthFilter, setWidthFilter] = useState([]);
    const [heightFilter, setHeightFilter] = useState([]);
    const [acrossFilter, setAcrossFilter] = useState([]);
    const [shapeFilter, setShapeFilter] = useState([]);
    // const [widthMinFilter, setWidthMinFilter] = useState(null);
    // const [widthMaxFilter, setWidthMaxFilter] = useState(null);
    // const [heightMinFilter, setHeightMinFilter] = useState(null);
    // const [heightMaxFilter, setHeightMaxFilter] = useState(null);
    // const [acrossMinFilter, setAcrossMinFilter] = useState(null);
    // const [acrossMaxFilter, setAcrossMaxFilter] = useState(null);
    const [coreFilter, setCoreFilter] = useState([]);
    const [odFilter, setOdFilter] = useState([]);

    // const [widthMin, setWidthMin] = useState(0);
    // const [widthMax, setWidthMax] = useState(15);
    // const [heightMin, setHeightMin] = useState(0);
    // const [heightMax, setHeightMax] = useState(15);
    // const [acrossMin, setAcrossMin] = useState(1);
    // const [acrossMax, setAcrossMax] = useState(4);

    const [othersFilter, setOthersFilter] = useState();

    const unfiltered =
        _.isEmpty(brandFilter) &&
        _.isEmpty(categoryFilter) &&
        _.isEmpty(materialFilter) &&
        _.isEmpty(widthFilter) &&
        _.isEmpty(heightFilter) &&
        _.isEmpty(acrossFilter) &&
        _.isEmpty(shapeFilter) &&
        _.isEmpty(coreFilter) &&
        _.isEmpty(odFilter) &&
        (_.isEmpty(othersFilter) ||
            !Object.values(othersFilter).find((filter) => !_.isEmpty(filter)));

    const [open, setOpen] = useState([
        'brand',
        'category',
        'material',
        'dimensions',
        'printer',
        'others',
    ]);

    const resetFilters = () => {
        setBrandFilter([]);
        setCategoryFilter([]);
        setMaterialFilter([]);
        setWidthFilter([]);
        setHeightFilter([]);
        setAcrossFilter([]);
        setShapeFilter([]);
        setCoreFilter([]);
        setOdFilter([]);
        setOthersFilter();
        setTimeout(() => {
            setReset(true);
        }, 0);
    };

    useEffect(() => {
        if (shouldReset) {
            resetFilters();
            setShouldReset(false);
        }
    });

    const [showAll, setShowAll] = useState([]);

    const { categories, brands } = useSelector((state) => state.items);
    const brandsObj = (brands || []).reduce((obj, brand) => {
        obj[brand.id] = brand;
        return obj;
    }, {});
    const categoriesObj = (categories || []).reduce((obj, category) => {
        obj[category.id] = category;
        return obj;
    }, {});

    const { data } = useSelector((state) => state.configurator);
    const { materials } = data || {};
    const materialsObj = (materials || []).reduce((obj, material) => {
        obj[material.smartID] = material;
        return obj;
    }, {});

    const shapeOrderObj = {
        RCR: 0,
        SQR: 1,
        CIR: 2,
        OVA: 3,
        CUS: 4,
        CONT: 5,
    };

    const shapeObj = {
        RCR: 'Rounded Corner Rectangle',
        SQR: 'Squared Corner Rectangle',
        CIR: 'Circle',
        OVA: 'Oval',
        CUS: 'Custom',
        CONT: 'Continuous',
    };

    const formatWidthHeight = (num) => {
        if (num < 1) {
            return 'less than 1"';
        }

        return `${num}"+`;
    };

    const formatShape = (shape) => shapeObj[shape] || shape;

    const formatInches = (num) => `${num}"`;

    const createNewOptions = () => {
        if (items.length !== max || _.isEmpty(options) || unfiltered) {
            const newFilterOrder = {};
            const newOptions = items.reduce(
                (nOptions, item) => {
                    const { brand, path, mfgData, filters } = item;
                    const { smartID, width, height, across, shape, core, od } =
                        mfgData || {};

                    if (brand && !hideBrands) {
                        if (nOptions.brands) {
                            if (!nOptions.brands.includes(brand))
                                nOptions.brands.push(brand);
                        } else {
                            nOptions.brands = [brand];
                        }
                    }

                    if (!hideCategories) {
                        path.forEach((categoryID) => {
                            const initialCategory = categoryID.split('.')[0];

                            if (!['000', '999'].includes(initialCategory)) {
                                if (nOptions.categories) {
                                    if (
                                        !nOptions.categories.includes(
                                            initialCategory
                                        )
                                    )
                                        nOptions.categories.push(
                                            initialCategory
                                        );
                                } else {
                                    nOptions.categories = [initialCategory];
                                }
                            }
                        });
                    }

                    if (!requireCategoryFilter || !_.isEmpty(categoryFilter)) {
                        if (smartID) {
                            if (nOptions.materials) {
                                if (!nOptions.materials.includes(smartID)) {
                                    nOptions.materials.push(smartID);
                                }
                            } else {
                                nOptions.materials = [smartID];
                            }
                        }

                        if (width) {
                            const widthName = Math.floor(width);

                            if (nOptions.widths) {
                                if (!nOptions.widths.includes(widthName)) {
                                    nOptions.widths.push(widthName);
                                }
                            } else {
                                nOptions.widths = [widthName];
                            }
                        }

                        if (height) {
                            const heightName =
                                Math.floor(height) > 15
                                    ? 15
                                    : Math.floor(height);

                            if (nOptions.heights) {
                                if (!nOptions.heights.includes(heightName)) {
                                    nOptions.heights.push(heightName);
                                }
                            } else {
                                nOptions.heights = [heightName];
                            }
                        }

                        if (across) {
                            if (nOptions.acrosses) {
                                if (!nOptions.acrosses.includes(across)) {
                                    nOptions.acrosses.push(across);
                                }
                            } else {
                                nOptions.acrosses = [across];
                            }
                        }

                        if (shape) {
                            if (nOptions.shapes) {
                                if (!nOptions.shapes.includes(shape))
                                    nOptions.shapes.push(shape);
                            } else {
                                nOptions.shapes = [shape];
                            }
                        }

                        // if (width) {
                        //     if (!nOptions.widthMin || width < nOptions.widthMin) {
                        //         nOptions.widthMin = width;
                        //     }
                        //     if (!nOptions.widthMax || width > nOptions.widthMax) {
                        //         nOptions.widthMax = width;
                        //     }
                        // }

                        // if (height) {
                        //     if (!nOptions.heightMin || height < nOptions.heightMin) {
                        //         nOptions.heightMin = height;
                        //     }
                        //     if (!nOptions.heightMax || height > nOptions.heightMax) {
                        //         nOptions.heightMax = height;
                        //     }
                        // }

                        // if (across) {
                        //     if (!nOptions.acrossMin || across < nOptions.acrossMin) {
                        //         nOptions.acrossMin = across;
                        //     }
                        //     if (!nOptions.acrossMax || across > nOptions.acrossMax) {
                        //         nOptions.acrossMax = across;
                        //     }
                        // }

                        if (core) {
                            if (nOptions.cores) {
                                if (!nOptions.cores.includes(core))
                                    nOptions.cores.push(core);
                            } else {
                                nOptions.cores = [core];
                            }
                        }

                        if (od) {
                            if (nOptions.ods) {
                                if (!nOptions.ods.includes(od))
                                    nOptions.ods.push(od);
                            } else {
                                nOptions.ods = [od];
                            }
                        }

                        if (filters) {
                            filters.forEach((filter) => {
                                const { description, attribute, sort } = filter;

                                if (nOptions.others[description]) {
                                    if (
                                        !nOptions.others[description].includes(
                                            attribute
                                        )
                                    ) {
                                        nOptions.others[description].push(
                                            attribute
                                        );

                                        // nOptions.others[description] =
                                        //     nOptions.others[description].sort(
                                        //         (a, b) => a.localeCompare(b)
                                        //     );
                                    }
                                } else {
                                    nOptions.others[description] = [attribute];
                                }

                                if (newFilterOrder[description]) {
                                    newFilterOrder[description][attribute] =
                                        sort;
                                } else {
                                    newFilterOrder[description] = {
                                        [attribute]: sort,
                                    };
                                }
                            });
                        }
                    }

                    return nOptions;
                },
                {
                    brands: [],
                    categories: [],
                    materials: [],
                    widths: [],
                    heights: [],
                    acrosses: [],
                    shapes: [],
                    cores: [],
                    ods: [],
                    others: {},
                }
            );

            newOptions.brands = brandFilter.length
                ? options.brands
                : newOptions.brands.sort(
                      (a, b) =>
                          (brandsObj[a] ? brandsObj[a].sortID : Infinity) -
                          (brandsObj[b] ? brandsObj[b].sortID : Infinity)
                  );

            newOptions.categories = categoryFilter.length
                ? options.categories
                : newOptions.categories.sort((a, b) => a.localeCompare(b));

            newOptions.materials = materialFilter.length
                ? options.materials
                : newOptions.materials.sort(
                      (a, b) =>
                          (materialsObj[a] ? materialsObj[a].sort : Infinity) -
                          (materialsObj[b] ? materialsObj[b].sort : Infinity)
                  );

            newOptions.widths = widthFilter.length
                ? options.widths
                : newOptions.widths.sort((a, b) => a - b);

            newOptions.heights = heightFilter.length
                ? options.heights
                : newOptions.heights.sort((a, b) => a - b);

            newOptions.acrosses = acrossFilter.length
                ? options.acrosses
                : newOptions.acrosses.sort((a, b) => a - b);

            newOptions.shapes = shapeFilter.length
                ? options.shapes
                : newOptions.shapes.sort(
                      (a, b) =>
                          (shapeOrderObj[a]
                              ? shapeOrderObj[a].sort
                              : Infinity) -
                          (shapeOrderObj[b] ? shapeOrderObj[b].sort : Infinity)
                  );

            newOptions.cores = coreFilter.length
                ? options.cores
                : newOptions.cores.sort((a, b) => a - b);

            newOptions.ods = odFilter.length
                ? options.ods
                : newOptions.ods.sort((a, b) => a - b);

            Object.entries(newOptions.others).forEach(
                ([description, attributes]) => {
                    if (
                        othersFilter &&
                        othersFilter[description] &&
                        othersFilter[description].length
                    ) {
                        newOptions.others[description] =
                            options.others[description];
                    } else {
                        newOptions.others[description] = attributes.sort(
                            (a, b) => {
                                const aSort =
                                    (newFilterOrder[description] || {})[a] || 0;
                                const bSort =
                                    (newFilterOrder[description] || {})[b] || 0;

                                return aSort - bSort;
                            }
                        );
                    }
                }
            );

            setOptions(newOptions);

            if (!othersFilter && Object.keys(newOptions.others).length) {
                setOthersFilter(
                    Object.keys(newOptions.others).reduce(
                        (obj, description) => {
                            obj[description] = [];
                            return obj;
                        },
                        {}
                    )
                );
            }
        }
    };

    useEffect(() => {
        createNewOptions();
    }, [items, max]);

    useEffect(() => {
        const hasOthersFilter = Object.values(othersFilter || {}).reduce(
            (boolean, array) => boolean || !_.isEmpty(array),
            false
        );

        if (items) {
            const newFilteredItems = items.filter((item) => {
                const { brand, path, mfgData, filters: itemFilters } = item;
                const { smartID, width, height, across, core, od, shape } =
                    mfgData || {};

                if (brandFilter.length && !brandFilter.includes(brand)) {
                    return false;
                }

                const categoryIDs = path.map(
                    (categoryID) => categoryID.split('.')[0]
                );

                if (
                    categoryFilter.length &&
                    !categoryFilter.some((categoryID) =>
                        categoryIDs.includes(categoryID)
                    )
                ) {
                    return false;
                }

                if (
                    materialFilter.length &&
                    (!smartID || !materialFilter.includes(smartID))
                ) {
                    return false;
                }

                if (
                    widthFilter.length &&
                    (!width || !widthFilter.includes(Math.floor(width)))
                ) {
                    return false;
                }

                if (
                    heightFilter.length &&
                    (!height || !heightFilter.includes(Math.floor(height)))
                ) {
                    return false;
                }

                if (
                    acrossFilter.length &&
                    (!across || !acrossFilter.includes(across))
                ) {
                    return false;
                }

                if (
                    shapeFilter.length &&
                    (!shape || !shapeFilter.includes(shape))
                ) {
                    return false;
                }

                // if (widthMinFilter && width < widthMinFilter) {
                //     return false;
                // }

                // if (widthMaxFilter && width > widthMaxFilter) {
                //     return false;
                // }

                // if (heightMinFilter && height < heightMinFilter) {
                //     return false;
                // }

                // if (heightMaxFilter && height > heightMaxFilter) {
                //     return false;
                // }

                // if (acrossMinFilter && across < acrossMinFilter) {
                //     return false;
                // }

                // if (acrossMaxFilter && across > acrossMaxFilter) {
                //     return false;
                // }

                if (
                    coreFilter.length &&
                    (!core || !coreFilter.includes(core))
                ) {
                    return false;
                }

                if (odFilter.length && (!od || !odFilter.includes(od))) {
                    return false;
                }

                if (hasOthersFilter) {
                    if (!itemFilters) {
                        return false;
                    } else {
                        let isMatch = true;

                        Object.entries(othersFilter || {}).forEach(
                            ([description, attributes]) => {
                                const itemAttributes = itemFilters.reduce(
                                    (array, itemFilter) => {
                                        if (
                                            itemFilter.description ===
                                            description
                                        ) {
                                            array.push(itemFilter.attribute);
                                        }

                                        return array;
                                    },
                                    []
                                );

                                if (
                                    attributes.length &&
                                    (!itemAttributes.length ||
                                        !attributes.some((attribute) =>
                                            itemAttributes.includes(attribute)
                                        ))
                                ) {
                                    isMatch = false;
                                }
                            }
                        );

                        return isMatch;
                    }
                }

                return true;
            });

            setFilteredItems(newFilteredItems);
            setRecalculate(false);
        }
    }, [recalculate]);

    /*
    const overlaps = itemFilters.filter(
                                (itemFilter) =>
                                    itemFilter.description === description
                            );
                            const isMatch = overlaps.length
                                ? attributes.reduce(
                                      (boolean, attribute) =>
                                          boolean ||
                                          overlaps.includes(attribute),
                                      false
                                  )
                                : false;

                            if (!isMatch) return false;
                            */

    const updateFilter = (event, filterType, option, value) => {
        const { checked } = event.target;

        if (filterType === 'brand') {
            if (checked && !brandFilter.includes(option)) {
                setBrandFilter([...brandFilter, option]);
            } else if (!checked && brandFilter.includes(option)) {
                setBrandFilter([...brandFilter].filter((o) => o !== option));
            }
        }

        if (filterType === 'category') {
            if (checked && !categoryFilter.includes(option)) {
                setCategoryFilter([...categoryFilter, option]);
            } else if (!checked && categoryFilter.includes(option)) {
                setCategoryFilter(
                    [...categoryFilter].filter((o) => o !== option)
                );
            }
        }

        if (filterType === 'material') {
            if (checked && !materialFilter.includes(option)) {
                setMaterialFilter([...materialFilter, option]);
            } else if (!checked && materialFilter.includes(option)) {
                setMaterialFilter(
                    [...materialFilter].filter((o) => o !== option)
                );
            }
        }

        if (filterType === 'width') {
            if (checked && !widthFilter.includes(option)) {
                setWidthFilter([...widthFilter, option]);
            } else if (!checked && widthFilter.includes(option)) {
                setWidthFilter([...widthFilter].filter((o) => o !== option));
            }
        }

        if (filterType === 'height') {
            if (checked && !heightFilter.includes(option)) {
                setHeightFilter([...heightFilter, option]);
            } else if (!checked && heightFilter.includes(option)) {
                setHeightFilter([...heightFilter].filter((o) => o !== option));
            }
        }

        if (filterType === 'across') {
            if (checked && !acrossFilter.includes(option)) {
                setAcrossFilter([...acrossFilter, option]);
            } else if (!checked && acrossFilter.includes(option)) {
                setAcrossFilter([...acrossFilter].filter((o) => o !== option));
            }
        }

        if (filterType === 'shape') {
            if (checked && !shapeFilter.includes(option)) {
                setShapeFilter([...shapeFilter, option]);
            } else if (!checked && shapeFilter.includes(option)) {
                setShapeFilter([...shapeFilter].filter((o) => o !== option));
            }
        }

        // if (filterType === 'width' && value[1] > value[0]) {
        //     setWidthMinFilter(value[0]);
        //     setWidthMaxFilter(value[1]);
        // }

        // if (filterType === 'height' && value[1] > value[0]) {
        //     setHeightMinFilter(value[0]);
        //     setHeightMaxFilter(value[1]);
        // }

        // if (filterType === 'across' && value[1] > value[0]) {
        //     setAcrossMinFilter(value[0]);
        //     setAcrossMaxFilter(value[1]);
        // }

        if (filterType === 'core') {
            if (checked && !coreFilter.includes(option)) {
                setCoreFilter([...coreFilter, option]);
            } else if (!checked && coreFilter.includes(option)) {
                setCoreFilter([...coreFilter].filter((o) => o !== option));
            }
        }

        if (filterType === 'od') {
            if (checked && !odFilter.includes(option)) {
                setOdFilter([...odFilter, option]);
            } else if (!checked && odFilter.includes(option)) {
                setOdFilter([...odFilter].filter((o) => o !== option));
            }
        }

        if (filterType === 'others') {
            if (
                checked &&
                othersFilter[option] &&
                !othersFilter[option].includes(value)
            ) {
                setOthersFilter({
                    ...othersFilter,
                    [option]: [...othersFilter[option], value],
                });
            } else if (
                !checked &&
                othersFilter[option] &&
                othersFilter[option].includes(value)
            ) {
                setOthersFilter({
                    ...othersFilter,
                    [option]: othersFilter[option].filter((v) => v !== value),
                });
            }
        }

        setReset(true);
        setTimeout(() => setRecalculate(true), 0);
    };

    const updateOpen = (filterType) => {
        if (!open.includes(filterType)) {
            setOpen([...open, filterType]);
        } else {
            setOpen([...open].filter((o) => o !== filterType));
        }
    };

    const updateShowAll = (filterType) => {
        if (!showAll.includes(filterType)) {
            setShowAll([...showAll, filterType]);
        } else {
            setShowAll([...showAll].filter((o) => o !== filterType));
        }
    };

    const Wrapper = ({ children, filterName, filterTitle, optionsSubset }) => (
        <div className="filter-box">
            {optionsSubset && optionsSubset.length && (
                <Collapse in={open.includes(filterName)} collapsedHeight={40}>
                    <div className="filter-container">
                        <div className="filter-option-head">
                            <div className="filter-option-header">
                                {filterTitle}
                            </div>
                            <IconButton
                                aria-label="collapse-filter"
                                onClick={() => updateOpen(filterName)}
                                size="small"
                                style={{ marginTop: -5 }}
                            >
                                <i
                                    className={
                                        open.includes(filterName)
                                            ? 'fas fa-chevron-down'
                                            : 'fas fa-chevron-up'
                                    }
                                ></i>
                            </IconButton>
                        </div>
                        {children}
                    </div>
                    {optionsSubset.length > 5 && (
                        <button
                            className="no-button bold blue orange"
                            style={{
                                display: 'block',
                                marginTop: -10,
                                marginBottom: '1rem',
                                fontSize: 12,
                            }}
                            onClick={() => updateShowAll(filterName)}
                        >
                            {showAll.includes(filterName)
                                ? 'Hide'
                                : `Show ${optionsSubset.length - 5} more`}
                        </button>
                    )}
                </Collapse>
            )}
        </div>
    );

    const renderFilterBox = (
        filterName,
        filterTitle,
        filter,
        filterObj,
        filterKey,
        optionsSubset,
        formatFunction,
        showMore
    ) => {
        if (optionsSubset.length > 1 || filter.length) {
            return (
                <>
                    {filterTitle && (
                        <div className="filter-option-subheader">
                            {filterTitle}
                        </div>
                    )}
                    <div className="filter-options">
                        <FormControl>
                            <FormGroup>
                                {optionsSubset
                                    .slice(
                                        0,
                                        showAll.includes(filterName)
                                            ? undefined
                                            : 5
                                    )
                                    .map((option) => (
                                        <FormControlLabel
                                            key={option}
                                            control={
                                                <Checkbox
                                                    checked={filter.includes(
                                                        option
                                                    )}
                                                    onChange={(e) =>
                                                        updateFilter(
                                                            e,
                                                            filterName,
                                                            option
                                                        )
                                                    }
                                                    name={`${option}`}
                                                    size="small"
                                                />
                                            }
                                            label={
                                                (filterObj[option] || {})[
                                                    filterKey
                                                ] ||
                                                (formatFunction
                                                    ? formatFunction(option)
                                                    : option)
                                            }
                                        />
                                    ))}
                            </FormGroup>
                        </FormControl>
                    </div>
                    {showMore && optionsSubset.length > 5 && (
                        <button
                            className="no-button bold blue orange"
                            style={{
                                display: 'block',
                                marginTop: -10,
                                marginBottom: '2rem',
                                fontSize: 12,
                            }}
                            onClick={() => updateShowAll(filterName)}
                        >
                            {showAll.includes(filterName)
                                ? 'Hide'
                                : `Show ${optionsSubset.length - 5} more`}
                        </button>
                    )}
                </>
            );
        }
    };

    const renderFilterBoxOther = (filterName, filterTitle, filter, options) => {
        if (
            options.length > 1 ||
            (filter && filter[filterName] && filter[filterName].length)
        ) {
            return (
                <div key={filterName} className="filter-box">
                    <div className="filter-container">
                        <div className="filter-option-head">
                            {filterTitle && (
                                <div className="filter-option-header">
                                    {filterTitle}
                                </div>
                            )}
                        </div>
                        <div className="filter-options">
                            <FormControl>
                                <FormGroup>
                                    {options
                                        .slice(
                                            0,
                                            showAll.includes(filterName)
                                                ? undefined
                                                : 5
                                        )
                                        .map((option) => (
                                            <FormControlLabel
                                                key={option}
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            filter &&
                                                            filter[filterName]
                                                                ? filter[
                                                                      filterName
                                                                  ].includes(
                                                                      option
                                                                  )
                                                                : false
                                                        }
                                                        onChange={(e) =>
                                                            updateFilter(
                                                                e,
                                                                'others',
                                                                filterName,
                                                                option
                                                            )
                                                        }
                                                        name={option}
                                                        size="small"
                                                    />
                                                }
                                                label={option}
                                            />
                                        ))}
                                </FormGroup>
                            </FormControl>
                        </div>
                        {options.length > 5 && (
                            <button
                                className="no-button bold blue orange"
                                style={{
                                    display: 'block',
                                    marginTop: -10,
                                    fontSize: 12,
                                }}
                                onClick={() => updateShowAll(filterName)}
                            >
                                {showAll.includes(filterName)
                                    ? 'Hide'
                                    : `Show ${options.length - 5} more`}
                            </button>
                        )}
                    </div>
                </div>
            );
        }
    };

    // const renderFilterBoxSlider = (
    //     filterName,
    //     filterTitle,
    //     min,
    //     max,
    //     currentMin,
    //     currentMax
    // ) => {
    //     if (min === max) return '';

    //     return (
    //         <>
    //             {filterTitle && (
    //                 <div className="filter-option-subheader">{filterTitle}</div>
    //             )}
    //             <div className="filter-options filter-options-dimensions">
    //                 <Slider
    //                     value={[currentMin, currentMax]}
    //                     onChange={(e, value) =>
    //                         updateFilter(e, filterName, null, value)
    //                     }
    //                     min={min}
    //                     max={max}
    //                     marks={[
    //                         { value: currentMin, label: `${currentMin}"` },
    //                         { value: currentMax, label: `${currentMax}"` },
    //                     ]}
    //                     valueLabelDisplay="off"
    //                     aria-labelledby="range-slider"
    //                 />
    //             </div>
    //         </>
    //     );
    // };

    const renderBrandsFilter = () => (
        <Wrapper
            filterName="brand"
            filterTitle="Brand"
            optionsSubset={options.brands}
        >
            {renderFilterBox(
                'brand',
                undefined,
                brandFilter,
                brandsObj,
                'name',
                options.brands
            )}
        </Wrapper>
    );
    const renderCategoriesFilter = () => (
        <Wrapper
            filterName="category"
            filterTitle="Category"
            optionsSubset={options.categories}
        >
            {renderFilterBox(
                'category',
                undefined,
                categoryFilter,
                categoriesObj,
                'name',
                options.categories
            )}
        </Wrapper>
    );
    const renderMaterialsFilter = () => (
        <Wrapper
            filterName="material"
            filterTitle="Material"
            optionsSubset={options.materials}
        >
            {renderFilterBox(
                'material',
                undefined,
                materialFilter,
                materialsObj,
                'name',
                options.materials
            )}
        </Wrapper>
    );
    const renderWidthFilter = () =>
        renderFilterBox(
            'width',
            'Label Width',
            widthFilter,
            {},
            'name',
            options.widths,
            formatWidthHeight,
            true
        );
    const renderHeightFilter = () =>
        renderFilterBox(
            'height',
            'Label Height',
            heightFilter,
            {},
            'name',
            options.heights,
            formatWidthHeight,
            true
        );
    const renderAcrossFilter = () =>
        renderFilterBox(
            'across',
            'Number Across',
            acrossFilter,
            {},
            'name',
            options.acrosses,
            true
        );
    const renderShapeFilter = () =>
        renderFilterBox(
            'shape',
            'Label Shape',
            shapeFilter,
            {},
            'name',
            options.shapes,
            formatShape,
            true
        );
    // const renderWidthFilter = () =>
    //     renderFilterBoxSlider(
    //         'width',
    //         'Label Width',
    //         widthMin,
    //         widthMax,
    //         widthMinFilter,
    //         widthMaxFilter
    //     );
    // const renderHeightFilter = () =>
    //     renderFilterBoxSlider(
    //         'height',
    //         'Label Height',
    //         heightMin,
    //         heightMax,
    //         heightMinFilter,
    //         heightMaxFilter
    //     );
    // const renderAcrossFilter = () =>
    //     renderFilterBoxSlider(
    //         'across',
    //         '# Across',
    //         acrossMin,
    //         acrossMax,
    //         acrossMinFilter,
    //         acrossMaxFilter
    //     );
    const renderCoreFilter = () =>
        renderFilterBox(
            'core',
            'Core Diameter',
            coreFilter,
            {},
            null,
            options.cores,
            formatInches
        );
    const renderOdFilter = () =>
        renderFilterBox(
            'od',
            'Outer Diameter',
            odFilter,
            {},
            null,
            options.ods,
            formatInches
        );
    const renderOthersFilters = () =>
        Object.entries(options.others || {}).map(([description, attributes]) =>
            renderFilterBoxOther(
                description,
                description,
                othersFilter,
                attributes
            )
        );

    const isFilterable = Object.entries(options).reduce(
        (boolean, [key, values]) =>
            boolean ||
            (key === 'others' &&
                Object.values(options.others || []).length > 1) ||
            values.length > 1,
        false
    );

    // console.log(
    //     brandFilter,
    //     categoryFilter,
    //     materialFilter,
    //     widthFilter,
    //     heightFilter,
    //     acrossFilter,
    //     shapeFilter,
    //     coreFilter,
    //     odFilter,
    //     othersFilter
    // );

    if (!isFilterable) return '';

    return (
        <div className="filter">
            <h3 className="filter-header">
                <span>Filters</span>
                <button
                    className="red orange"
                    onClick={resetFilters}
                    style={{
                        border: 'none',
                        background: 'transparent',
                        padding: 0,
                        fontWeight: 'bold',
                    }}
                >
                    Reset
                </button>
            </h3>
            {!!(
                !hideCategories &&
                (options.categories.length > 1 || categoryFilter.length)
            ) && renderCategoriesFilter()}
            {!!(
                !hideBrands &&
                (options.brands.length > 1 || brandFilter.length)
            ) && renderBrandsFilter()}
            {!!(options.materials.length > 1 || materialFilter.length) &&
                renderMaterialsFilter()}
            {!!(
                options.widths.length > 1 ||
                widthFilter.length ||
                options.heights.length > 1 ||
                heightFilter.length ||
                options.acrosses.length > 1 ||
                acrossFilter.length ||
                options.shapes.length > 1 ||
                shapeFilter.length
            ) && (
                <div className="filter-box">
                    <Collapse
                        in={open.includes('dimensions')}
                        collapsedHeight={40}
                    >
                        <>
                            <div className="filter-container filter-container-slider">
                                <div className="filter-option-head">
                                    <div className="filter-option-header">
                                        Dimensions
                                    </div>
                                    <IconButton
                                        aria-label="collapse-filter"
                                        onClick={() => updateOpen('dimensions')}
                                        size="small"
                                        style={{ marginTop: -5 }}
                                    >
                                        <i
                                            className={
                                                open.includes('dimensions')
                                                    ? 'fas fa-chevron-down'
                                                    : 'fas fa-chevron-up'
                                            }
                                        ></i>
                                    </IconButton>
                                </div>
                                {renderWidthFilter()}
                                {renderHeightFilter()}
                                {renderAcrossFilter()}
                                {renderShapeFilter()}
                            </div>
                        </>
                    </Collapse>
                </div>
            )}
            {!!(
                options.cores.length > 1 ||
                coreFilter.length ||
                options.ods.length > 1 ||
                odFilter.length
            ) && (
                <div className="filter-box">
                    <Collapse
                        in={open.includes('printer')}
                        collapsedHeight={40}
                    >
                        <>
                            <div className="filter-container filter-container-slider">
                                <div className="filter-option-head">
                                    <div className="filter-option-header">
                                        Printer
                                    </div>
                                    <IconButton
                                        aria-label="collapse-filter"
                                        onClick={() => updateOpen('printer')}
                                        size="small"
                                        style={{ marginTop: -5 }}
                                    >
                                        <i
                                            className={
                                                open.includes('printer')
                                                    ? 'fas fa-chevron-down'
                                                    : 'fas fa-chevron-up'
                                            }
                                        ></i>
                                    </IconButton>
                                </div>
                                {renderCoreFilter()}
                                {renderOdFilter()}
                            </div>
                        </>
                    </Collapse>
                </div>
            )}
            {renderOthersFilters()}
            <button
                className="red orange"
                onClick={resetFilters}
                style={{
                    border: 'none',
                    background: 'transparent',
                    padding: 0,
                    fontWeight: 'bold',
                }}
            >
                Reset Filters
            </button>
        </div>
    );
}
